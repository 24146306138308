<template>
	<div class="body-function">
		<div style="display: inline-block; width: 100%; height: 100%">
			<div class="child-top" style="padding-top: 0">
				<div class="input-from">
					<el-date-picker
						v-model="date"
						type="daterange"
						align="right"
						unlink-panels
						format="yyyy-MM-dd"
						value-format="yyyy-MM-dd"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						:picker-options="pickerOptions"
						@change="create(1)"
						class="picker_date"
					></el-date-picker>
					<el-cascader
						@change="searchCreate"
						style="float: left"
						placeholder="请选择运动队"
						clearable
						v-model="deparmentId"
						:options="departmentOptions"
						:show-all-levels="false"
						collapse-tags
						:props="{ children: 'children', label: 'name', value: 'uuid', emitPath: false, multiple: true }"
					></el-cascader>
					<div>
						<el-input
							@keyup.enter.native="create(1)"
							@clear="create(1)"
							class="left-child-input left-child-input-content"
							clearable
							placeholder="请输入标题关键字"
							style="width: 200px; float: left"
							suffix-icon="iconfont iconsousuo"
							v-model="inputValue"
						></el-input>
						<el-button class="searchBtn" round @click="create(1)">搜索</el-button>
					</div>
				</div>

				<div class="operation-button">
					<el-button @click="addDataLog" class="new_btn" operation-button round :disabled="$store.getters.permissionsStr('新增')">
						<span>新增</span>
					</el-button>
					<el-button @click="delMoreData" class="del_btn" round :disabled="$store.getters.permissionsStr('删除') || !tableDataSelectArr.length">
						<span>删除</span>
					</el-button>
				</div>
			</div>

			<div class="content-flex-pages" style="background: #fff">
				<!-- :data="!$store.getters.permissionsStr('列表') ? tableData : []" -->
				<!-- :data="tableData" -->
				<el-table
					:data="!$store.getters.permissionsStr('列表') ? tableData : []"
					:empty-text="tableData.length ? '' : '暂无数据'"
					@selection-change="tableSelectionChange"
					class="content-test-table"
					style="width: 100%"
					v-loading="tableLoading"
				>
					<el-table-column type="selection" width="45"></el-table-column>
					<el-table-column label="序号" type="index" width="45">
						<template slot-scope="scope">{{ (currentPage - 1) * 10 + scope.$index + 1 }}</template>
					</el-table-column>
					<el-table-column label="标题" min-width="120" prop="title" show-overflow-tooltip></el-table-column>
					<el-table-column label="运动队" prop="department_name" show-overflow-tooltip></el-table-column>
					<el-table-column label="测试日期" prop="test_date" show-overflow-tooltip></el-table-column>
					<el-table-column label="创建人" prop="create_by" show-overflow-tooltip></el-table-column>
					<el-table-column label="创建日期" prop="create_time" show-overflow-tooltip></el-table-column>
					<el-table-column label="附件" prop="filter" show-overflow-tooltip>
						<template slot-scope="scope">
							<el-button style="color: #0055e9" v-show="scope.row.uuid" type="text" @click="uploadBtn(scope.row)" :disabled="$store.getters.permissionsStr('附件')">{{
								`附件 (${scope.row.annex_quantity >= 0 ? scope.row.annex_quantity : ''})`
							}}</el-button>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="200">
						<template slot-scope="scope">
							<el-button
								class="right20"
								style="color: #0055e9"
								size="medium"
								type="text"
								v-show="scope.row.uuid"
								@click="editorData(scope.row, scope.$index)"
								:disabled="$store.getters.permissionsStr('编辑')"
								>编辑</el-button
							>
							<el-button
								class="right20"
								style="color: #1a9f16"
								size="medium"
								type="text"
								v-show="scope.row.uuid"
								@click="lookData(scope.row, scope.$index)"
								:disabled="$store.getters.permissionsStr('查看')"
								>查看</el-button
							>
							<el-button
								@click="delTableData(scope.row)"
								class="redColor"
								size="medium"
								style="color: #fd3258"
								type="text"
								v-show="scope.row.uuid"
								:disabled="$store.getters.permissionsStr('删除')"
								>删除</el-button
							>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination
					:current-page.sync="currentPage"
					:page-size="10"
					:total="tableTotal"
					:key="elementui_page_component_key"
					@current-change="handleCurrentChange"
					class="table-pagination"
					layout="total, prev, pager, next , jumper"
				></el-pagination>
			</div>
		</div>

		<!-- 附件弹框 -->
		<el-dialog :title="`附件列表 (${fileNum})`" :visible.sync="fileDialogVisible" :close-on-click-modal="false" width="50%" :show-close="false" center class="newDialog">
			<div class="form_bot">
				<div class="inter_opBtn" v-loading="fileLoading">
					<el-upload
						class="upload-demo"
						:action="`${$store.state.img_url}/p/annex/add`"
						:data="{ master_uuid: is_uuid, last_path: '2' }"
						:on-success="success"
						:before-upload="beforeUpload"
						:show-file-list="false"
						:file-list="newFileList"
					>
						<el-button type="primary" round>上传</el-button>
					</el-upload>
					<el-button type="primary" @click="upload_down" round>下载</el-button>
					<el-button type="primary" round @click="option_del">删除</el-button>
				</div>
				<el-checkbox-group v-model="checkList">
					<ul class="upload_ul">
						<li v-for="(item, index) in files" :key="index">
							<div :class="checkList.indexOf(item.name) != -1 ? 'img_option img_active' : 'img_option'" @click="fileTofile(item)">
								<img :src="item.url" alt />
								<div class="img_dialog" v-if="checkList.indexOf(item.name) != -1"></div>
							</div>
							<el-checkbox :label="item.name">{{ index + 1 }}</el-checkbox>
							<p>{{ item.name }}</p>
						</li>
					</ul>
				</el-checkbox-group>
			</div>

			<el-dialog :visible.sync="imgDialog" append-to-body class="append_dialog">
				<img width="100%" :src="dialogImageUrl" alt />
			</el-dialog>
			<span slot="footer" class="form-bottom-button">
				<!-- <el-button @click="fileSave" class="save_btn" round>保 存</el-button> -->
				<el-button @click="fileClose" class="cancel_btn" round>关 闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import wordUrl from './../../../static/word.jpg'
import xlsxUrl from './../../../static/xlsx.jpg'
import pptsUrl from './../../../static/ppts.jpg'
import pdfUrl from './../../../static/pdfs.jpg'
import morenUrl from './../../../static/file.png'
export default {
	data() {
		return {
			wordUrl: wordUrl,
			xlsxUrl: xlsxUrl,
			pptsUrl: pptsUrl,
			pdfUrl: pdfUrl,
			morenUrl: morenUrl,
			is_uuid: '',
			date: [],
			fileNum: 0,
			inputValue: '',
			deparmentId: '',
			files: [],
			newFileList: [],
			departmentOptions: [],
			tableDataSelectArr: [], // 多选
			tableData: [{ uuid: 0 }], // 表格
			checkList: [], //附件列表
			currentPage: 1,
			tableTotal: 0,
			dialogTableVisible: false,
			fileDialogVisible: false,
			fileLoading: false,
			tableLoading: false,
			imgDialog: false, // 附件图片查看
			dialogImageUrl: '', // 附件查看图片地址
			pickerOptions: {
				shortcuts: [
					{
						text: '最近一周',
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
							picker.$emit('pick', [start, end])
						},
					},
					{
						text: '最近一个月',
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
							picker.$emit('pick', [start, end])
						},
					},
					{
						text: '最近三个月',
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
							picker.$emit('pick', [start, end])
						},
					},
					{
						text: '最近一年',
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setFullYear(start.getFullYear() - 1)
							picker.$emit('pick', [start, end])
						},
					},
				],
			},
			elementui_page_component_key: 0,
		}
	},
	created() {
		this.$set(this, 'currentPage', Number(this.$route.query.page))
	},
	watch: {},
	computed: {},
	mounted() {
		var obj = JSON.parse(sessionStorage.getItem('arch_search'))
		if (obj) {
			this.date = obj.date
			this.deparmentId = obj.deparmentId
			this.inputValue = obj.inputValue
			this.currentPage = obj.currentPage
			this.elementui_page_component_key++
		}

		this.create(this.currentPage)
		this.getDeparment()
	},
	methods: {
		getPageSize() {
			var num = Math.ceil(this.tableTotal / 10)
			this.currentPage = num
		},
		searchCreate() {
			this.create()
		},
		create(page) {
			this.tableLoading = true
			// 列表数据
			this.$axios
				.post(
					'/bodyfunc/lists',
					this.$qs({
						page: page ? page : 1,
						page_size: 10,
						type: '1',
						department_uuid: this.deparmentId.toString(','),
						start_date: this.date ? this.date[0] : '',
						end_date: this.date ? this.date[1] : '',
						keyword: this.inputValue,
					})
				)
				.then(res => {
					if (res.data.code === 0) {
						this.tableData = res.data.data.rows
						this.tableTotal = res.data.data.total
					} else {
						if (res.data.msg !== undefined) {
							this.$message({
								message: res.data.msg.message,
								type: 'warning',
							})
						} else {
							this.$utils.message({
								type: 'error',
								message: res.data.message,
							})
						}
					}
					this.tableLoading = false
				})
				.catch(error => {
					console.log(error)
					this.tableLoading = false
				})
		},
		getDeparment() {
			this.$axios.post('/p/staff/tree').then(res => {
				if (res.data.code == 0) {
					this.departmentOptions = res.data.data.department
				} else {
					this.$message({
						type: 'error',
						message: res.data.message,
					})
				}
			})
		},
		// 附件弹框
		uploadBtn(rows) {
			this.fileDialogVisible = true
			this.fileNum = rows.annex_quantity
			this.is_uuid = rows.uuid

			this.$axios
				.post(
					'/p/annex/lists',
					this.$qs({
						uuid: rows.uuid,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.files = res.data.data.rows
						res.data.data.rows.forEach((itm, index) => {
							// item.url = 'http://' + item.file_path
							let type = this.nameMatch(itm)
							if (type) {
								this.files[index].url = type
							} else {
								this.files[index].url = 'http://' + this.files[index].file_path
							}
						})
						this.newFileList = res.data.data.rows
					}
					setTimeout(() => {
						this.fileLoading = false
					}, 200)
				})
		},
		// 附件上传拦截
		beforeUpload(file) {
			const size = file.size / 1024 / 1024
			if (size > 20) {
				this.$message({
					title: 'warning',
					message: '文件大小超过20M',
				})
				return false
			} else {
				return true
			}
		},
		// 附件下载
		upload_down() {
			if (!this.checkList.length) {
				this.$message({
					type: 'error',
					message: '请选择要下载的附件',
				})
				return false
			}
			let arr = []
			this.checkList.forEach((item, index) => {
				this.files.forEach(itm => {
					if (item == itm.name) {
						arr.push(itm.file_path)
					}
				})
			})

			let path = ''
			arr.forEach((i, k) => {
				if (k == arr.length - 1) {
					path += `file_array[${k}]=${i}`
				} else {
					path += `file_array[${k}]=${i}&`
				}
			})

			let url = this.$store.state.img_url + '/p/download/zip?' + path
			window.open(url)
		},
		// 附件删除
		option_del() {
			let arr = ''
			this.checkList.forEach((item, index) => {
				this.files.forEach(itm => {
					if (item == itm.name) {
						if (index == 0) {
							arr = itm.file_path
						} else {
							arr += ',' + itm.file_path
						}
					}
				})
			})

			this.$axios
				.post(
					`/p/annex/delete`,
					this.$qs({
						file_path: arr,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.$message({
							type: 'success',
							message: res.data.message,
						})
						this.fileNum = this.fileNum - this.checkList.length
						let checkLength = this.checkList.length
						while (checkLength--) {
							var length = this.files.length
							while (length--) {
								if (this.files[length].name == this.checkList[checkLength]) {
									this.files.splice(length, 1)
									this.checkList.splice(checkLength, 1)
								}
							}
						}
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
		},
		// 附件弹框关闭
		fileClose() {
			this.fileDialogVisible = false
			this.create(this.currentPage)
		},
		// 新增
		addDataLog() {
			sessionStorage.setItem('arch_search', JSON.stringify({ date: this.date, deparmentId: this.deparmentId, inputValue: this.inputValue, currentPage: this.currentPage }))

			this.$router.push('/textItem/bodyFunctionAdd')
		},
		// 编辑
		editorData(row, index) {
			sessionStorage.setItem('arch_search', JSON.stringify({ date: this.date, deparmentId: this.deparmentId, inputValue: this.inputValue, currentPage: this.currentPage }))

			this.$router.push({
				path: '/textItem/bodyFunctionAdd',
				query: {
					uuid: row.uuid,
				},
			})
		},
		lookData(row, index) {
			sessionStorage.setItem('arch_search', JSON.stringify({ date: this.date, deparmentId: this.deparmentId, inputValue: this.inputValue, currentPage: this.currentPage }))

			this.$router.push({
				path: '/textItem/bodyFunctionAdd',
				query: {
					uuid: row.uuid,
					name: 'look',
				},
			})
		},
		// 单行删除
		delTableData(row) {
			this.$confirm('确定删除数据吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true,
			}).then(() => {
				this.$axios
					.post(
						'/bodyfunc/delete',
						this.$qs({
							uuid_str: row.uuid,
						})
					)
					.then(res => {
						if (res.data.code === 0) {
							this.$message({
								message: res.data.message,
								type: 'success',
							})

							this.tableTotal = this.tableTotal - 1
							this.getPageSize()
							this.create(this.currentPage)
						} else {
							this.$utils.message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
					.catch(error => {})
			})
		},
		// 多行删除
		delMoreData(row) {
			var touristIds = ''
			// 选择的账号
			for (var i = 0; i < this.tableDataSelectArr.length; i++) {
				touristIds += `${this.tableDataSelectArr[i].uuid},`
			}
			touristIds = touristIds.substring(0, touristIds.length - 1)
			this.$confirm('确定删除数据吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true,
			}).then(() => {
				this.$axios
					.post(
						'/bodyfunc/delete',
						this.$qs({
							uuid_str: touristIds,
						})
					)
					.then(res => {
						if (res.data.code === 0) {
							this.$message({
								message: res.data.message,
								type: 'success',
							})
							this.tableTotal = this.tableTotal - this.tableDataSelectArr.length
							this.getPageSize()
							this.create(this.currentPage)
						} else {
							this.$utils.message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
					.catch(error => {})
			})
		},
		//成功的时候
		success(res, file, fileList) {
			if (res.code == 0) {
				this.fileNum++
				this.files.push(file.response.data)
				// this.$message({
				//   type: "success",
				//   message: res.message
				// });
			} else {
				this.$message({
					type: 'error',
					message: res.message,
				})
				for (let i in fileList) {
					if (fileList[i].name == file.name) {
						fileList.splice(i, 1)
					}
				}
			}
			let type = this.nameMatch(file)
			if (type) {
				this.files.forEach(item => {
					if (item.name == file.name) {
						item.url = type
					}
				})
			} else {
				this.files.forEach(item => {
					if (item.name == file.name) {
						item.url = 'http://' + item.file_path
					}
				})
			}
		},

		nameMatch(name) {
			let suffix = '' // 后缀
			let result = '' // 类型结果
			let imgUrl = ''

			var fileArr = name.name.split('.')
			suffix = fileArr[fileArr.length - 1]

			if (!suffix) {
				result = false
				return result
			}

			let typeList = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff']
			result = typeList.some(item => {
				return item == suffix
			})
			if (result) {
				return false
			}

			switch (suffix) {
				case 'docx':
					imgUrl = this.wordUrl
					break
				case 'rtf':
					imgUrl = this.wordUrl
					break
				case 'xls':
					imgUrl = this.xlsxUrl
					break
				case 'xlsx':
					imgUrl = this.xlsxUrl
					break
				case 'ppt':
					imgUrl = this.pptsUrl
					break
				case 'pptx':
					imgUrl = this.pptsUrl
					break
				case 'pdf':
					imgUrl = this.pdfUrl
					break
				default:
					imgUrl = this.morenUrl
					break
			}
			return imgUrl
		},
		fileTofile(item) {
			var fileArr = item.name.split('.')
			let suffix = fileArr[fileArr.length - 1]

			if (suffix == 'docx' || suffix == 'rtf' || suffix == 'xls' || suffix == 'xlsx' || suffix == 'ppt' || suffix == 'pptx' || suffix == 'pdf') {
				return false
			}
			this.imgDialog = true
			this.dialogImageUrl = item.url
		},
		// 分页
		handleCurrentChange(val) {
			this.currentPage = val
			this.create(val)
		},
		// 多选
		tableSelectionChange(val) {
			this.tableDataSelectArr = val
		},
		// 查看当前页数
		getNum(data) {
			var num = data / 10,
				pages = ''
			if (num > Math.floor(num)) {
				pages = Math.ceil(num)
			} else {
				pages = num
			}
			return pages
		},
	},
}
</script>

<style lang="scss" scoped>
.newDialog {
	/deep/ .el-dialog {
		.form_bot {
			// height: 330px;
			overflow-x: auto;
			margin-top: 20px;
			border-radius: 5px;
			border: 1px solid #ccc;
			min-height: 200px;
		}
	}
}

.inter_upload {
	margin-top: 20px;
	border-radius: 5px;
	border: 1px solid #ccc;
	min-height: 200px;
}

.inter_opBtn {
	display: flex;
	margin-top: 30px;
	justify-content: flex-end;
	padding-right: 30px;
	.upload-demo {
		margin-right: 10px;
	}
	.el-button {
		width: 110px;
		height: 40px;
		border: none;
		&:nth-child(1) {
			background: #35c8cb;
		}
		&:nth-child(2) {
			background: #6a6ef8;
		}
		&:nth-child(3) {
			background: #ed4a68;
		}
	}
}

.el-form {
	margin-bottom: 30px;
	.formItem_cen {
		display: flex;
		.el-form-item {
			flex: 1;
			.el-date-editor {
				width: 100%;
				border-bottom: none;
				.el-input__inner {
					border-color: #0055e9 !important;
				}
			}
			.el-select {
				margin-top: 0;
				width: 100%;
			}
		}
	}
}
.inter_file {
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 20px;
	p {
		line-height: 16px;
		font-size: 17px;
		margin-bottom: 20px;
		span {
			display: inline-block;
			width: 5px;
			height: 16px;
			border-radius: 20px;
			background: #0055e9;
		}
	}
}

.upload_ul {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	margin: 20px 0;
	// padding-bottom: 10px;
	li {
		width: 150px;
		text-align: center;
		margin-left: 20px;
		position: relative;
		&:last-child {
			margin-right: 20px;
		}
		.el-checkbox {
			position: absolute;
			top: 0px;
			left: 0px;
			/deep/ .el-checkbox__inner {
				width: 20px;
				height: 20px;
				&::after {
					width: 6px;
					height: 13px;
					left: 5px;
				}
			}
			/deep/ .el-checkbox__label {
				display: none;
			}
		}
		&:hover {
			cursor: pointer;
		}
		&:first-child {
			margin-left: 20px;
		}
		.img_option {
			width: 150px;
			height: 150px;
			border-radius: 10px;
			border: 1px solid #ccc;
			overflow: hidden;
			position: relative;
			// border: 2px solid #fff;
			.img_dialog {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba(0, 85, 233, 0.11);
			}
		}
		.img_active {
			border: 1px solid #0055e9;
		}
		img {
			width: 100%;
			max-height: 150px;
		}
		p {
			margin: 10px;
			font-size: 16px;
			line-height: 22px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			overflow: hidden;
		}
	}
}

.body-function {
	padding-top: 10px;
}
.child-top {
	border-radius: 5px;
	.input-from {
		float: left;
		min-width: 1100px;
		margin-bottom: 0;

		.picker_date {
			float: left;
			margin-top: 20px;
		}

		& > .el-select {
			width: 150px;
			float: left;
			margin-top: 20px;
		}

		.el-select,
		.el-input {
			margin-top: 20px;
		}

		.el-input.el-input--suffix.left-child-input {
			float: left;
			width: 200px;
		}

		.el-cascader {
			margin-top: 18px;
			min-width: 200px;
		}

		.searchBtn {
			margin-top: 20px;
		}
	}

	.operation-button {
		// float: left;
		margin-bottom: 10px;
	}
}
.ModifyDoalog {
	margin-top: -20px;
	padding: 0 0 0 20px;
	& > p {
		font-size: 20px;
		color: #3b3b3b;
		text-align: center;
		margin-bottom: 20px;
	}
	.el-form {
		padding-right: 30px;
		.form_inRact {
			display: flex;
			margin-bottom: 20px;
			.el-form-item:first-child {
				margin-right: 5%;
			}
		}
	}
}
.table-pagination {
	text-align: right;
	margin-top: 10px;
	/deep/ span {
		float: left;
	}
}
.newTable {
	height: 400px;
	overflow: auto;
	.el-table {
		width: 40%;
	}
}

/deep/ input::-webkit-input-placeholder {
	color: #999;
}
/deep/ input::-moz-input-placeholder {
	color: #999;
}
/deep/ input::-ms-input-placeholder {
	color: #999;
}

.iconshanchu-moren {
	&:hover {
		color: #f00;
		cursor: pointer;
	}
}

.el-pagination {
	background: #fff;
	padding: 10px;
	margin-top: 0;
}

.child-top .operation-button .del_btn:hover {
	background: #eb1e44;
	color: #ffffff;
}
/deep/.el-cascader__tags .el-tag {
	max-width: 63% !important;
}
</style>
<style>
.el-input__icon {
	/* color: #000;
    font-weight: 700; */
}
</style>
